<template>
    <v-row>
        <v-col class="hidden-sm-and-down"></v-col>
        <v-col cols="12" md="11">
            <div class="px-4">
                <div data-aos="flip-left" data-aos-duration="700" data-aos-offset="100" data-aos-delay="200">
                    <div class="sub-title-text">
            <span style="font-size:2.5rem;">
                CAMPING
            </span>
                        <p style="font-size:1.1rem;">
                            고객님들이 편안한 휴식을 취할수 있도록 항상 최선을 다하고 있습니다.
                        </p>
                    </div>
                </div>
                <v-row class="my-10">
                    <v-col cols="12" class="pa-0 hidden-sm-and-down">
                        <div data-aos="fade-up" data-aos-duration="700" data-aos-offset="100" data-aos-delay="100">
                            <swiper class="swiper"
                                    :options="swiperOption2"
                                    ref="roomSwiper"
                            >
                                <template v-for="(room, i) in roomList">
                                    <swiper-slide>
                                        <img class="main-slide-img-2" :src="room.roomThumb" @click="goToCampInfo(room)"/>
                                        <div class="main-slide-text text-center mt-4" style="width: 100%;">
                                            <span class="main-slide-title">{{room.roomNm}}</span>
                                            <span class="main-slide-text">최대{{room.maxNop}}인 , {{room.roomForm}}</span>
                                            <v-btn
                                                    color="primary"
                                                    class="mx-3 white--text center-block tmp-btn"
                                                    text
                                                    @click="goToCampInfo(room)"
                                            >
                                                DETAIL VIEW >
                                            </v-btn>
                                        </div>
                                    </swiper-slide>
                                </template>
                            </swiper>
                        </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 hidden-md-and-up">
                        <div data-aos="fade-up" data-aos-duration="700" data-aos-offset="100" data-aos-delay="100">
                            <swiper class="swiper"
                                    :options="swiperOption"
                                    ref="roomSwiper"
                            >
                                <template v-for="(room, i) in roomList">
                                    <swiper-slide>
                                        <img class="main-slide-img-2" :src="room.roomThumb" @click="goToCampInfo(room)"/>
                                        <div class="main-slide-text text-center mt-4" style="width: 100%;">
                                            <span class="main-slide-title">{{room.roomNm}}</span>
                                            <span class="main-slide-text">최대{{room.maxNop}}인 , {{room.roomForm}}</span>
                                            <v-btn
                                                    color="primary"
                                                    class="mx-3 white--text center-block tmp-btn"
                                                    text
                                                    @click="goToCampInfo(room)"
                                            >
                                                DETAIL VIEW >
                                            </v-btn>
                                        </div>
                                    </swiper-slide>
                                </template>
                            </swiper>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col></v-col>
    </v-row>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainRoomInfo2',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            //swiper: null,
            roomList: [],
            swiperOption: {
                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                loopAdditionalSlides:3,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
            swiperOption2: {
                loopAdditionalSlides:3,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
        }),
        created: function () {
            this.getList({roomGubun: 'R'});
        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            }
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("room/getCampList", {})
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;

                            setTimeout(() => {
                                this.roomSwiper.$swiper.slideTo(3, 500);
                            }, 300)
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            goToCampInfo(item) {

                location.href = "/place/roomList/roomInfo" + '?roomId=' + item.roomId+'&roomGubun='+item.roomGubun;

            },
        },
    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
