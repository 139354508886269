<template>
    <v-row>
        <v-col class="hidden-sm-and-down">

        </v-col>
        <v-col cols="12" md="11">
            <div class="px-4">
                <div data-aos="flip-left" data-aos-duration="700" data-aos-offset="100" data-aos-delay="200">
                    <div class="sub-title-text">
                        <span style="font-size:2.5rem;">
                            CAMPING
                        </span>
                        <p style="font-size:1.1rem;">
                            고객님들이 편안한 휴식을 취할수 있도록 항상 최선을 다하고 있습니다.
                        </p>
                    </div>
                </div>
                <v-row class="my-10">
                    <v-col cols="12" md="4" class="hidden-sm-and-down">
                        <div class="main-div-1" >
                            <div class="main-title-text-3">
                                <p style="font-size:3rem; margin-bottom :50px !important;">
                                    C A M P I N G
                                </p>
                                <p style="font-size:1.1rem; margin-bottom :16px !important;">
                                    캠핑장 A, D  :  그늘막, 평상 + 1
                                </p>
                                <p style="font-size:1.1rem; margin-bottom :50px !important;">
                                    캠핑장 B, C  :  나무그늘
                                </p>
                                <v-btn
                                        color="white"
                                        class="mx-3 white--text center-block tmp-btn mt-5"
                                        outlined
                                        x-large
                                        @click="goToList"
                                >
                                    DETAIL VIEW >
                                </v-btn>
                            </div>

                        </div>

                    </v-col>
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col
                                    v-for="(item,n) in roomList"
                                    :key="n"
                                    class="d-flex child-flex"
                                    cols="12" sm="6">
                                <v-card
                                        outlined
                                        class="roomListCard"
                                        @click="goToInfo(item)">
                                    <v-img
                                            :src="item.roomThumb"
                                            :lazy-src="item.roomThumb"
                                            aspect-ratio="1.7"
                                            class="grey lighten-2"
                                    >
                                        <div class="sub-title-text-2" style="margin-top: 130px;">
                                            <p class="text-h4" style="font-weight: bold; color:#fff">
                                                {{item.roomNm}}
                                            </p>

                                        </div>
                                    </v-img>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col>

        </v-col>
    </v-row>

</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainRoomInfo2',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            //swiper: null,
            roomList: [],
            swiperOption: {
                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                loopAdditionalSlides: 3,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                }
            },
            swiperOption2: {
                loopAdditionalSlides: 3,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
        }),
        created: function () {
            this.getList({roomGubun: 'R'});
        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            }
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("room/getCampList", {})
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;

                            setTimeout(() => {
                                //this.roomSwiper.$swiper.slideTo(3, 500);
                            }, 300)
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            goToInfo(item) {

                location.href = "/place/roomList/roomInfo" + '?roomId=' + item.roomId + '&roomGubun=' + item.roomGubun;
            },
            goToList() {

                this.$router.push({path: "/place/main?pageId=1"});

            },

        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
