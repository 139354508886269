<template>
    <div>
        <v-row>
            <v-col>
                <div data-aos="flip-left" data-aos-duration="700" data-aos-offset="100" data-aos-delay="200">
                    <div class="sub-title-text">
                        <span style="font-size:2.5rem;">
                            ROOMS (펜션)
                        </span>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!--<v-row>
            <v-col cols="12" md="6">
                <div data-aos="flip-left" data-aos-duration="700" data-aos-offset="50" data-aos-delay="200">
                    <div class="main-slide-text-div px-16">
                        <template v-for="(room, i) in roomList">
                            <span :id="i" @click="toSlide(i)" class="main-slide-text">{{room.roomNm}}</span>
                        </template>

                    </div>
                </div>
            </v-col>
        </v-row>-->
        <v-row data-aos="flip-left" data-aos-duration="700" data-aos-offset="200"
               data-aos-delay="100">
            <v-col cols="12" class="hidden-sm-and-down">
                <ul class="roundBtn-blue calc-8" style="display: flex;">
                    <li v-for="(item, i) in roomList" :key="i" class="">
                        <a :id="i" @click="toSlide(i)"> {{item.roomNm}}</a>
                    </li>
                </ul>
            </v-col>
            <v-col cols="12" class="hidden-md-and-up">
                <template v-if="roomList.length > 5">
                    <ul class="roundBtn-blue calc-4">
                        <li v-for="(item, i) in roomList" :key="i">
                            <a :id="'_'+i" @click="toSlide(i)"> {{item.roomNm}}</a>
                        </li>
                    </ul>
                </template>
            </v-col>
        </v-row>
        <v-row class="my-3">
            <v-col cols="12" md="6" class="pa-0" data-aos="flip-left" data-aos-duration="700" data-aos-offset="200"
                   data-aos-delay="100">
                <div>
                    <swiper class="swiper" :options="swiperOption" @slideChange="setChangeSlide" ref="roomSwiper">
                        <template v-for="(room, i) in roomList">
                            <swiper-slide>
                                <img class="main-slide-img" :src="room.roomThumb" @click="goToInfo(room)"/>
                            </swiper-slide>
                        </template>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                    </swiper>
                </div>
            </v-col>
            <v-col cols="12" md="6" style="background: #112E35; height: 60vh;" class="pa-0 hidden-sm-and-down" data-aos="flip-left"
                   data-aos-duration="700" data-aos-offset="200" data-aos-delay="100">
                <div>
                    <div class="sub-title-text-2" style="margin-top: 200px;">
                        <p style="font-size:2.5rem;">
                            ROOMS (펜션)
                        </p>
                        <p style="font-size:1.1rem;">
                            고객님들이 편안한 휴식을 취할수 있도록<br> 항상 최선을 다하고 있습니다.
                        </p>
                        <v-btn
                                color="white"
                                class="mx-3 white--text center-block tmp-btn mt-5"
                                outlined
                                x-large
                                @click="goToList"
                        >
                            DETAIL VIEW >
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" class="hidden-md-and-up">
                <ul style="width:100%; margin:0 auto !important;" class="roundBtn-blue calc-1">
                    <li>
                        <a @click="goToList"
                           style="font-weight: bold; border: 2px solid #1976d2;">DETAIL VIEW</a>
                    </li>
                </ul>
            </v-col>
        </v-row>


    </div>
</template>

<script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

    // Import Swiper styles
    import 'swiper/css/swiper.css'

    export default {
        name: 'MainRoomInfo',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            //swiper: null,
            roomList: [],
            swiperOption: {
                spaceBetween: 0,
                effect: 'Creative',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                /*autoplay: {
                    delay: 5000,
                },*/
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                /*rewind: true,*/
                initialSlide: 1,
                loop: true,
                /*loopedSlides: 0,*/

            },
            nowSlide: 0,
        }),
        created: function () {
            this.getList({roomGubun: 'R', useYn:"Y"});

        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            }

        },
        methods: {
            getList(val) {

                return this.$store.dispatch("room/getRoomList", val)
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;

                            this.$nextTick(() => {
                                this.setChangeSlide();
                                this.$nextTick(() => {
                                    //this.roomSwiper.$swiper.slideTo(2, 500);
                                })

                            })


                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            toSlide(val) {
                this.nowSlide = val+1;
                this.roomSwiper.$swiper.slideTo(this.nowSlide , 500);
            },
            goToInfo(item) {
                location.href = "/place/roomList/roomInfo" + '?roomId=' + item.roomId+'&roomGubun='+item.roomGubun;
            },
            goToList() {

                this.$router.push({path: "/place/main?pageId=2"});

            },
            setChangeSlide(){

                var activeIndex = this.roomSwiper.$swiper.activeIndex;
                var slidesLen = this.roomSwiper.$swiper.slides.length;

                if(this.roomSwiper.$swiper.params.loop){
                    switch(this.roomSwiper.$swiper.activeIndex){
                        case 0:
                            activeIndex = slidesLen-3;
                            break;
                        case slidesLen-1:
                            activeIndex = 0;
                            break;
                        default:
                            --activeIndex;
                    }
                }

                $('.roundBtn-blue > li > a').removeClass("active");
                $("#" + activeIndex).addClass("active");
                $("#_" + activeIndex).addClass("active");

            }
        }

    }

</script>

<style lang="scss" scoped>

</style>

