<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div v-for="popInfo in popList">
            <div :id="'layerPop'+popInfo.popId" class="layerPop">
                <table>
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <td>
                                        <div @click="clickPop(popInfo)" style="cursor: pointer; width: 100%; height: 100%;">
                                            <template v-if="popInfo.popDataType == 0">
                                                <div class="layerBody">
                                                    <v-img
                                                            :src="popInfo.popImg"
                                                            :lazy-src="popInfo.popImg"
                                                            class="grey lighten-2"

                                                    ></v-img>
                                                </div>
                                            </template>
                                            <template v-else-if="popInfo.popDataType ==1">
                                                <div id="" class="cont">
                                                    <div class="ql-editor">
                                                        <span v-html="popInfo.popContent"></span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="background-color: #eaeaea" align="right" class="layerBottom">
                                    <input type="checkbox" :id="'check'+popInfo.popId" value="Y">
                                    <span class="mx-3">하루동안 창을 표시하지 않음</span>
                                    <a href="#" @click="closeWin(popInfo.popId)" class="mx-3">닫기</a>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'MainPopup',
        data: () => ({
            popList: [],
            popInfo: {
                popId: "",
                popType: "",
                popTitle: "",
                popContent: "",
                popImg: "",
                popStartDate: "",
                popEndDate: "",
                popTop: "",
                popLeft: "",
                popWidth: "",
                popHeight: "",
                popScroll: "",
                popDataType: "",
                popLink: "",
                popLinkType: "",
                useYn: "",
                regDate: "",
                lstModPrs: "",
                lstModDate: "",

            },
        }),
        mounted() {

        },
        created() {

            this.fetch()

        },
        methods: {
            fetch() {

                var today = new Date();

                var year = today.getFullYear();
                var month = ('0' + (today.getMonth() + 1)).slice(-2);
                var day = ('0' + today.getDate()).slice(-2);

                var dateString = year + '-' + month + '-' + day;

                return this.$store.dispatch("pop/getPopList", {"today": dateString, useYn:"Y"})
                    .then((resp) => {
                        setTimeout(() => {

                            this.popList = resp.message;

                        }, 300)
                    })
                    .catch((err) => {
                    })

            },
            clickPop(val) {

                if (val.popLink != undefined && val.popLink.charAt(0) === '/') {
                    switch (val.popLinkType) {
                        case "0" : { //새창
                            window.open(val.popLink);
                            break;
                        }
                        case "1" : { //현재창
                            location.href = val.popLink;
                            break;
                        }
                        case "2" : { //메인창?
                            location.href = val.popLink;
                            break;
                        }
                    }
                }


            },
            openPop() {

                let _this = this;

                _this.popList.forEach(function (e) {


                    let cookie = _this.getCookie('layerPop' + e.popId);

                    if (cookie === 'Y') {
                        return;
                    }

                    _this.$nextTick(() => {
                        switch (e.popType) {
                            case 'true': {  // 팝업

                                let options = 'top=' + e.popTop +
                                    ', left=' + e.popLeft +
                                    ', width=' + e.popWidth +
                                    ', height=' + e.popHeight +
                                    ', status=no, menubar=no, toolbar=no, resizable=no';
                                let route = '/popup/read?popId=' + e.popId;
                                let popNm = "팝업" + e.popId;
                                window.open(route, popNm, options);
                                break;
                            }
                            case 'false': { // 레이어

                                var pop = document.getElementById('layerPop' + e.popId);

                                pop.style.display = "table";
                                pop.style.top = e.popTop + "px";
                                pop.style.left = e.popLeft + "px";


                                if (e.popDataType === '0') {
                                    pop.style.width = e.popWidth + "px";
                                    pop.style.height = Number(e.popHeight) + 30 + "px";
                                } else {
                                    pop.style.minwidth = e.popWidth + "px";
                                    pop.style.minheight = Number(e.popHeight) + 30 + "px";

                                    //pop.style.width = e.popWidth + "px";
                                    //pop.style.height = Number(e.popHeight) + 30 + "px";
                                }


                                if (e.popScroll === 'true') {
                                    pop.style.overflow = 'auto';
                                } else {
                                    pop.style.overflow = 'hidden';
                                }

                                $("#layerPop" + e.popId).fadeIn(400);

                                break;
                            }

                        }
                    })

                });

            },
            closeWin(key) {
                if ($("#check" + key).prop("checked")) {

                    this.setCookie('layerPop' + key, 'Y', 7);
                }

                $("#layerPop" + key + "").hide();

            },
            setCookie(cname, cvalue, exdays) {
                var todayDate = new Date();
                todayDate.setTime(todayDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + todayDate.toUTCString(); // UTC기준의 시간에 exdays인자로 받은 값에 의해서 cookie가 설정 됩니다.
                document.cookie = cname + "=" + cvalue + "; " + expires;
            },
            getCookie(cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');

                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];

                    if (c.indexOf(name) !== -1) {
                        //Cookie.push(c);
                        return 'Y'
                    }
                }
                return "N";
            },
        },
        computed: {},
        updated: function () {  // 페이지 전부 생성후 자동 실행
            this.openPop();
        }
    }
</script>

<style>

</style>
