<template>
    <v-row>
        <v-col class="hidden-sm-and-down">

        </v-col>

        <v-col cols="12" md="11">
            <div class="px-4">
                <div>
                    <div class="sub-title-text">
                        <span style="font-size:2.5rem;">
                            VALLEY
                        </span>
                        <p style="font-size:1.1rem;">
                            <span style="color:darkblue">1급 수가 흐르는 최고의 천연계곡!</span>
                        </p>
                    </div>
                </div>
                <v-row class="my-10">
                    <v-col cols="12" class="pa-0 hidden-sm-and-down">
                        <div data-aos="fade-up" data-aos-duration="700" data-aos-offset="300" data-aos-delay="150">
                            <swiper class="swiper"
                                    :options="swiperOption2"
                                    ref="roomSwiper"
                            >
                                <template v-for="(room, i) in roomList">
                                    <swiper-slide>
                                        <img class="main-slide-img-2" :src="room.roomImgRoute"
                                             @click="goToRoomInfo(room)"/>
                                    </swiper-slide>
                                </template>
                                <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                            </swiper>
                        </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 hidden-md-and-up">
                        <div data-aos="fade-up" data-aos-duration="700" data-aos-offset="300" data-aos-delay="150">
                            <swiper class="swiper"
                                    :options="swiperOption"
                                    ref="roomSwiper1"
                            >
                                <template v-for="(room, i) in roomList">
                                    <swiper-slide>
                                        <img class="main-slide-img-4" :src="room.roomImgRoute"
                                             @click="goToRoomInfo(room)"/>
                                    </swiper-slide>
                                </template>
                            </swiper>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col>

        </v-col>
    </v-row>

</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainValleyInfo',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            //swiper: null,
            roomList: [],
            swiperOption: {
                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
            swiperOption2: {
                loopAdditionalSlides:3,
                spaceBetween: 10,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
        }),
        created: function () {
            this.getList();

        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            }
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getRoomImg", {roomId: 'VA'})
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;
                            setTimeout(() => {
                                this.roomSwiper.$swiper.slideTo(3, 500);
                            }, 300)
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
        }

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
