<template>
    <div>
        <main-popup></main-popup>
        <!--<main-pop-layer ref="mainPopLayer"></main-pop-layer>-->
        <div id="layerMap1" class="layerPop">
            <table>
                <tr>
                    <td>
                        <div class="layerBody">
                            <template v-if="isMap">
                                <img
                                        :src="layerMap1Info.roomImgRoute"
                                        class="grey lighten-2"
                                        style="width: 100%;"

                                />
                            </template>
                            <template v-else>
                                <v-col cols="12" md="9">
                                    <div id="map" style="width:100%;height:480px;"></div>
                                </v-col>
                            </template>

                        </div>
                    </td>
                </tr>
                <tr style="background-color: #eaeaea" align="center" class="layerBottom">
                    <a href="#" @click="closeMap('layerMap1')" class="mx-3">닫기</a>

                </tr>
            </table>
        </div>
        <v-row>

            <v-col cols="12" class="hidden-sm-and-down">
                <div>
                    <!--<div style="background-image: url('/file/main_title2.png'); filter: brightness(70%); background-size: cover; background-repeat: no-repeat; height: 70vh;vertical-align: middle;">

                    </div>-->
                    <!--<div style="background-image: url('@/assets/title/main_title.jpg'); filter: brightness(70%); background-size: cover; background-repeat: no-repeat; height: 70vh;vertical-align: middle;">

                    </div>-->
                    <div class="main-title-img-pc"></div>
                    <v-row>
                        <div class="main-title-text" style="">
                            <span style="font-size: 2rem;">
                                1급수가 흐르는 중부권 <span style="font-size:2.5rem;">최고의 천연계곡</span>
                            </span>
                            <p style="font-size: 3.3rem;">
                                대둔산 운주계곡 계곡일번지
                            </p>
                        </div>

                    </v-row>
                    <v-row>
                        <div class="quickMenu">
                            <template v-for="(item, i) in items">
                                <template>
                                    <a :href="item.link">
                                        <span>
                                           <div class="material-icons material-icons-main quickIcon">
                                                {{ item.icon }}
                                            </div>
                                        </span>
                                        <div class="mt-4 text-center quickText">
                                            {{ item.title }}
                                        </div>
                                    </a>
                                </template>

                            </template>
                        </div>
                    </v-row>
                </div>

                <!--<img src="/file/main_title2.png" style="width: 100%; filter: brightness(50%);" 130>-->

            </v-col>
            <v-col cols="12" class="hidden-md-and-up">
                <div class="main-title-img-mo"></div>
                <v-row>
                    <v-col>
                        <div class="main-title-text" style="margin-top: 0;">
                            <span style="font-size: 2.5rem; margin-top:3px !important;">
                                계곡일번지
                            </span>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <div class="quickMenu quickMenu-md" style="margin-top: 3vh; top: 12vh; margin-left:12px;">
                        <template v-for="items3 in items2">
                            <p>
                                <template v-for="(item, i) in items3">
                                    <template>
                                        <a :href="item.link">
                                            <span>
                                               <div class="material-icons material-icons-main quickIcon">
                                                    {{ item.icon }}
                                                </div>
                                            </span>
                                            <div class="mt-4 text-center quickText">
                                                {{ item.title }}
                                            </div>
                                        </a>
                                    </template>

                                </template>
                            </p>
                        </template>
                    </div>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <main-camp-info3></main-camp-info3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <main-room-info></main-room-info>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <main-fac-info></main-fac-info>
            </v-col>
        </v-row>
        <v-row style="background: #eee">
            <v-col>
                <main-valley-info></main-valley-info>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <main-bench-info></main-bench-info>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import MainRoomInfo from '../components/MainRoomInfo'
    import MainCampInfo from '../components/MainCampInfo'
    import MainCampInfo3 from '../components/MainCampInfo3'
    import MainFacInfo from '../components/MainFacInfo'
    import MainBenchInfo from '../components/MainBenchInfo'
    import MainValleyInfo from '../components/MainValleyInfo'
    import MainPopup from '../components/MainPopup'
    import MainPopLayer from "../components/MainPopLayer";

    export default {
        name: 'Home2',

        components: {
            MainPopLayer,
            MainRoomInfo,
            MainCampInfo,
            MainCampInfo3,
            MainFacInfo,
            MainBenchInfo,
            MainValleyInfo,
            MainPopup,
        },
        data: () => ({
            items: [
                {
                    title: '시설안내',
                    icon: 'image',
                    link: '/place/main',
                },
                {
                    title: '이용안내',
                    icon: 'warning_amber',
                    link: '/userGuide/main',
                },
                {
                    title: '예약하기',
                    icon: 'event_note',
                    link: '/reservation/search',
                },
                {
                    title: '일번지소개',
                    icon: 'star',
                    link: '/about/main',
                },
                {
                    title: '커뮤니티',
                    icon: 'campaign',
                    link: '/community/notice',
                },
                {
                    title: '배치도',
                    icon: 'map',
                    link: '/about/main?pageId=3',
                },

            ],
            items2: [
                [
                    {
                        title: '시설안내',
                        icon: 'image',
                        link: '/place/main',
                    },
                    {
                        title: '이용안내',
                        icon: 'warning_amber',
                        link: '/userGuide/main',
                    },
                    {
                        title: '예약하기',
                        icon: 'event_note',
                        link: '/reservation/search',
                    },
                ],
                [
                    {
                        title: '일번지소개',
                        icon: 'star',
                        link: '/about/main',
                    },
                    {
                        title: '커뮤니티',
                        icon: 'campaign',
                        link: '/community/notice',
                    },
                    {
                        title: '배치도',
                        icon: 'map',
                        link: '/about/main?pageId=3',
                    },

                ],
            ],
            transparent: 'rgba(255, 255, 255, 0)',
            noticeList: [
                {
                    noticeId: "1",
                    noticeGubun: "true",
                    noticeTitle: "공지1",
                    noticeContent: "/file/73da934b-197c-46f3-90a3-53fa8f58e5c4.png",
                    noticeOrder: "1",
                    useYn: "Y",
                    regDate: "",
                    lstModPrs: "",
                    lstModDate: ""
                },

            ],
            isMap: true,
            layerMap1Info: {},
        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            this.setPop();

        },
        methods: {
            setPop() {

            },
            openLayer(val) {
                /*let options = 'top=10, left=10, width=550, height=840, status=no, menubar=no, toolbar=no, resizable=no';
                let route = '/popup/place/campMap';
                let popNm = "배치도";
                window.open(route, popNm, options);*/

                //this.$parent.clickBtn_main(4);
                //this.$refs.mainPopLayer.openPop();

                //this.openPop();

                if(val === 'map'){
                    this.isMap = true;
                    return this.$store.dispatch("admin/getRoomImg", {roomId: 'BAT'})
                        .then((resp) => {
                            setTimeout(() => {
                                this.$nextTick(() => {

                                    this.layerMap1Info = resp.message[0];
                                    var pop = document.getElementById('layerMap1');

                                    let height = document.documentElement.scrollTop + 30;
                                    let left = "30px";

                                    if(this.$isMobile()){
                                        left = "0px";
                                    }else {
                                        left = "30px";
                                    }

                                    pop.style.display = "grid";
                                    pop.style.top = height + "px";
                                    pop.style.left = left;
                                    //pop.style.transform = "translate(-50%, -50%)";

                                    pop.style.overflow = 'auto';

                                    $("#layerMap1").fadeIn(400);


                                });

                            }, 300)
                        })
                        .catch((err) => {
                        })

                }

                if(val === 'moving'){
                    /*this.isMap = false;
                    this.$nextTick(() => {
                        var mapOptions = {
                            center: new naver.maps.LatLng(36.01830091015144, 127.35030939019462),
                            zoom: 11
                        };
                        /!*36.01836533947182, 127.34992248215671*!/
                        var map = new naver.maps.Map('map', mapOptions);

                        var marker = new naver.maps.Marker({
                            position: new naver.maps.LatLng(36.01830091015144, 127.35030939019462),
                            map: map
                        });

                    })

                    var pop = document.getElementById('layerMap1');


                    let height = document.documentElement.scrollTop + 50;
                    //let height1 = document.querySelector('html').scrollTop;

                    pop.style.display = "grid";
                    pop.style.top = height + "px";
                    //pop.style.left = "50%";
                    //pop.style.transform = "translate(-50%, -50%)";

                    pop.style.width = '100%'

                    pop.style.overflow = 'auto';

                    $("#layerMap1").fadeIn(400);*/

                    this.isMap = true;
                    return this.$store.dispatch("admin/getRoomImg", {roomId: 'WTC'})
                        .then((resp) => {
                            setTimeout(() => {
                                this.$nextTick(() => {

                                    this.layerMap1Info = resp.message[0];
                                    var pop = document.getElementById('layerMap1');

                                    let height = document.documentElement.scrollTop +30;
                                    let left = "30px";

                                    if(this.$isMobile()){
                                        left = "0px";
                                    }else {
                                        left = "30px";
                                    }

                                    pop.style.display = "grid";
                                    pop.style.top = height + "px";
                                    pop.style.left = left;

                                    pop.style.overflow = 'auto';

                                    $("#layerMap1").fadeIn(400);


                                });

                            }, 300)
                        })
                        .catch((err) => {
                        })


                }


            },
            closeMap(IdName) {
                var pop = document.getElementById(IdName);
                pop.style.display = "none";
            },

        },


    }
</script>

<style scoped>
    .v-card {
        transition: opacity .4s ease-in-out;
    }

    .v-card:not(.on-hover) {
        opacity: 0.6;
    }

    .show-btns {
        color: rgba(255, 255, 255, 1) !important;
    }
</style>

